import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  generating: false,
  messages: [],
  page: 1,
  limit: 50,
  hasMore: true,
  total: 0,
}

const init_message = {
  role: 'assistant',
  content: "Hey, I'm Abby - What's been on your mind?",
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    getMessages(state) {
      state.loading = true;
    },
    getMessagesSuccess(state, action) {
      state.loading = false;
      let messages = action.payload.messages;
      if (messages.length === state.limit) {
        state.hasMore = true;
        state.messages = [...state.messages, ...(messages || [])];
      } else {
        state.hasMore = false;
        state.messages = [...state.messages, ...(messages || [])/* , init_message */];
      }
      state.total = state.messages.filter(msg => msg.role == 'user').length;
    },
    getMessagesFailure(state) {
      state.loading = false;
      state.messages = [];
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setHasMore(state, action) {
      state.hasMore = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setGenerating(state, action) {
      state.generating = action.payload;
    },
    setMessages(state, action) {
      state.messages = action.payload;
      state.total = state.messages.filter(msg => msg.role == 'user').length;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    initMessage(state, action) {
      state.loading = false;
      state.messages = [];
      state.page = 1;
      state.limit = 50;
      state.hasMore = true;
      state.total = 0;
    },
  },
});

export const {
  getMessages,
  getMessagesSuccess,
  getMessagesFailure,
  setSelectedMessage,
  setPage,
  setHasMore,
  setLimit,
  setGenerating,
  setMessages,
  setTotal,
  initMessage,
} = messageSlice.actions;
export default messageSlice.reducer;
