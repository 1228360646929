import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, message, Typography, Radio, Input, Tag } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckCircleTwoTone, LockOutlined } from '@ant-design/icons';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import AbbySrc from '../../../../assets/images/Abby_3x.png';
import { setTo } from '../../../../redux/app/appSlice';
import { getUser } from '../../../../redux/auth/authSlice';

import { _createSubscription, createSetupIntent, createSubscription } from '../../../../services/planAPI';

const { Title } = Typography;

function PayModal({ open, setOpen, price, planId, setSuccessful }) {
  const cardNumberRef = useRef();
  const cardExpiryRef = useRef();
  const cardCvcRef = useRef();

  // stripe items
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  // const errors = useSelector((state) => state.auth.errors);
  const to = useSelector((state) => state.app.to);
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);

  const [cardType, setCardType] = useState(1);

  const [cardError, setCardError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubscription = async () => {
    try {
      setLoading(true);
      // create a payment method
      let paymentMethod;
      let customerId;
      let msg = 'Your subscription started successfully!';
      if (!user.pm_last_four || (user.pm_last_four && cardType === 2)) {
        let { data } = await createSetupIntent();
        const { setupIntent, error: setupError } = await stripe?.confirmCardSetup(data.clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: user.name,
              email: user.email,
            },
          },
        });

        if (setupError) {
          // console.log(setupError);
          setCardError({
            ...cardError,
            [setupError.code.replace('incomplete_', '').replace('incorrect_', '')]: setupError.message,
          });
          message.error(setupError.message);
          setLoading(false);
          return;
        }
        // console.log(setupIntent);
        paymentMethod = setupIntent.payment_method;
        customerId = data.customerId;
      }

      let { data } = await _createSubscription({
        paymentMethod: paymentMethod,
        planId,
        customerId
      });
      if (data.status == 'trialing') {
        msg = 'Your free trial subscription started successfully!';
      } else if (data.requiresAction) {
        const { paymentIntentId } = data;
        const { error: confirmError } = await stripe.confirmCardPayment(paymentIntentId);
        if (confirmError) {
          console.error(confirmError);
          return;
        }
        console.log('Subscription confirmed!');
      } else {
        console.log('Subscription created without additional authentication');
      }
      dispatch(getUser());
      setOpen(false);
      setLoading(false);
      setTimeout(() => {
        console.log(to);
        navigate(to);
      }, 1000);
      message.success(msg);
      window.gtag('event', 'conversion', { 'send_to': 'AW-16565329006/84qjCODxss0ZEO64-9o9' });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else if (error.message) {
        message.error(error.message);
      }
    }
  };

  /* useEffect(() => {
    if ((to && to !== '/playground' && to !== '/voice-playground') && user.activeSubscription && (user.activeSubscription.status == 'trialing' || user.activeSubscription.status == 'active')) {
      dispatch(setTo());
      navigate(to);
    } else {
    }
  }, [user.activeSubscription]); */

  return (
    <Modal
      className="my-2 shadow-none"
      width={400}
      title={
        <div className='flex flex-col'>
          <div className='flex items-center justify-center mb-4'>
            <img src={AbbySrc} alt="abby_logo" width={70} className='mr-2' />
            <Tag className='font-bold text-primary'>Pro</Tag>
          </div>
          <div className="text-center px-4 py-1.5 rounded-lg mx-4 bg-[#e2d0a1]">
            <Title level={4} className="!mb-1 !text-primary !font-[700]">
              {user.pm_last_four ? `Only ${price} / Month` : '7 Day Free Trial'}
            </Title>
            <span className="italic">{user.pm_last_four ? `Only $${price} / Month. Cancel Anytime` : `Only $${price} / Month. Cancel Anytime`}</span>
            {/* <div className='my-2 text-left'>
              <CheckCircleTwoTone /> <span>Unlimited Messages / Month</span>
            </div>
            <div className='my-2 text-left'>
              <CheckCircleTwoTone /> <span>Voice Mode</span>
            </div>
            <div className='my-2 text-left'>
              <CheckCircleTwoTone /> <span>Follow Ups</span>
            </div> */}
          </div>
        </div>
      }
      open={open}
      centered
      footer={[]}
      maskClosable={true}
      classNames={{
        content: "!bg-primary !h-[420px]",
        mask: classNames(!siderCollapsed && "!ms-72"),
        wrapper: classNames(!siderCollapsed && "!ms-72"),
        header: "!bg-transparent",
      }}
      styles={{
        content: { placeContent: 'center', boxShadow: 'none' },
      }}
      onCancel={() => setOpen(false)}
      closable={false}
    >
      <div className="grid gap-2 m-auto">
        <div className="grid gap-2 px-4">
          {user.pm_last_four && (
            <Radio.Group
              onChange={(e) => {
                setCardType(e.target.value);
                setCardError({});
              }}
              value={cardType}
            >
              <Radio value={1} style={{ color: 'white' }}>
                Use Old Card
              </Radio>
              <Radio value={2} style={{ color: 'white' }}>
                Use New Card
              </Radio>
            </Radio.Group>
          )}
          {cardType === 1 && user.pm_last_four && (
            <div className="mt-2">
              <Input size="large" readOnly value={`* * * *  * * * *  * * * *  ${user.pm_last_four}`} />
            </div>
          )}

          {(cardType === 2 || !user.pm_last_four) && (
            <>
              <CardNumberElement
                className="px-4 py-3 mt-4 bg-white border border-gray-400 border-solid rounded"
                onChange={(event) => {
                  if (event.error) {
                    setCardError({ ...cardError, number: event.error.message });
                  } else {
                    setCardError({
                      ...cardError,
                      number: null,
                    });
                  }
                  if (event.complete) {
                    cardExpiryRef.current.focus();
                  }
                }}
                onReady={(element) => (cardNumberRef.current = element)}
              />
              {cardError.number && <div className="text-sm text-red-500">{cardError.number}</div>}
              <div className="flex flex-wrap justify-between">
                <div className="w-[50%]">
                  <CardExpiryElement
                    className="px-4 py-3 mt-4 bg-white border border-gray-400 border-solid rounded"
                    onChange={(event) => {
                      if (event.error) {
                        setCardError({
                          ...cardError,
                          expiry: event.error.message,
                        });
                      } else {
                        setCardError({
                          ...cardError,
                          expiry: null,
                        });
                      }
                      if (event.complete) {
                        cardCvcRef.current.focus();
                      } else if (event.empty) {
                        cardNumberRef.current.focus();
                      }
                    }}
                    onReady={(element) => (cardExpiryRef.current = element)}
                  />
                  {cardError.expiry && <div className="text-sm text-red-500">{cardError.expiry}</div>}
                </div>
                <div className="w-[40%]">
                  <CardCvcElement
                    className="px-4 py-3 mt-4 bg-white border border-gray-400 border-solid rounded"
                    onChange={(event) => {
                      if (event.error) {
                        setCardError({
                          ...cardError,
                          cvc: event.error.message,
                        });
                      } else {
                        setCardError({
                          ...cardError,
                          cvc: null,
                        });
                      }
                      if (event.empty) {
                        cardExpiryRef.current.focus();
                      }
                    }}
                    onReady={(element) => (cardCvcRef.current = element)}
                  />
                  {cardError.cvc && <div className="text-sm text-red-500">{cardError.cvc}</div>}
                </div>
              </div>
            </>
          )}

          <Button
            loading={loading}
            type="default"
            htmlType="submit"
            className="mt-8 font-[700]"
            disabled={!stripe || cardError.number || cardError.exp || cardError.cvc}
            size="large"
            onClick={handleSubscription}
            icon={<LockOutlined />}
            shape='round'
          >
            {/* {user.pm_last_four ? 'Subscribe' : 'Continue Conversation'} */}
            {user.pm_last_four ? 'Upgrade to PRO' : 'Start Your Free Trial'}
          </Button>
          <p className="my-2 italic text-center text-gray-500"></p>
        </div>
      </div>
    </Modal>
  );
}

export default PayModal;
