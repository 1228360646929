import React from "react";
import { Avatar, Dropdown } from "antd";
import { BiExpandVertical, BiUserVoice } from "react-icons/bi";
import { BsFillChatRightTextFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  LockOutlined,
  UserOutlined,
  AreaChartOutlined,
  SafetyOutlined,
  IdcardOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import constants from "../../../config/constants";
import { setStorage } from "../../../helpers";
import { logout } from "../../../redux/auth/authSlice";
import { initMessage } from "../../../redux/message/messageSlice";
import { initThread, setSelectedThread } from "../../../redux/thread/threadSlice";

const UserMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const items = [
    {
      label: (
        <div className="flex items-center gap-1 max-w-[250px]">
          <Avatar
            size="large"
            src={
              user.avatar
                ? `${constants.SOCKET_URL}${user.avatar}`
                : "/imgs/avatar.png"
            }
          />
          <div className="flex-1 truncate">
            <h3 className='m-0' title={user.name}>{user.name}</h3>
            <span title={user.email}>{user.email}</span>
          </div>
        </div>
      ),
      key: "user",
      type: "group",
      children: [
        {
          label: 'Text Chat',
          key: '/playground',
          icon: <BsFillChatRightTextFill />,
        },
        {
          label: 'Voice Chat',
          key: '/voice-playground',
          icon: <BiUserVoice className="!text-lg" />,
        },
        {
          label: "Profile",
          key: "/user/profile",
          icon: <IdcardOutlined />,
        },
      ],
    },
    {
      label: "Admin",
      key: "admin",
      type: "group",
      icon: <SafetyOutlined />,
      children: [
        {
          label: "Users",
          key: "/admin/users",
          icon: <UserOutlined />,
        },
        {
          label: 'Dashboard',
          key: '/admin/dashboard',
          icon: <AreaChartOutlined />,
        },
        {
          label: "Conversations",
          key: "/admin/conversations",
          icon: <MessageOutlined />,
        },
        {
          label: 'Compare',
          key: '/admin/compare',
          icon: <AreaChartOutlined />,
        },
      ],
    },
    {
      label: "Log Out",
      key: "/auth/logout",
      icon: <LockOutlined />,
    },
  ];

  const handleClick = ({ item, key }) => {
    if (key === "/auth/logout") {
      dispatch(initThread());
      dispatch(initMessage());
      setStorage("logout", true);
      dispatch(logout());
    } else {
      navigate(key);
    }
  };

  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) => item.key != "admin" || (item.key == "admin" && user.isAdmin)
        ),
        onClick: handleClick,
      }}
      trigger="click"
      placement="bottomLeft"
      arrow
    >
      {/* <Button type='primary' size='large' icon={<UserOutlined />}>{user.name}</Button> */}
      <div className='flex items-center gap-1 cursor-pointer'>
        <Avatar
          size="large"
          src={
            user.avatar
              ? `${constants.SOCKET_URL}${user.avatar}`
              : "/imgs/avatar.png"
          }
        />
        <div className="flex-1 truncate">
          <h3 className='m-0' title={user.name}>{user.name}</h3>
          <span title={user.email}>{user.email}</span>
        </div>
        <BiExpandVertical />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
